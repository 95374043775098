import React from "react"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Footer from "../components/Footer.es"

const IndexEN = ({location}) => {
  return (
    <>
      <Metatags
        title="Multilógica | 404"
        description="Proveedor de maquinaria para colchones"
        url="https://newsite.multilogica.ind.br/es/404"
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="colchón, máquina de coser, mammut, beckmann sew"
        language="es"
      />
      <NavBar location={location["pathname"]} />
      <div className="h-20" />
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-2" />
          <div className="col-span-8 text-center">
          <div className="text-5xl font-semibold text-mi-blue">Error</div>
            <div className="text-9xl font-semibold text-mi-blue">404</div>
            <div className="text-xl font-semibold text-mi-blue">No se encuentra la página que buscas.</div>
          </div>
        </div>
        <div className="h-10" />
      </div>
      <div className="h-20" />
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default IndexEN
